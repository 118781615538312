<template>
  <div>
    <div class="tabs">
      <ul>
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'stats.orders' }"
          custom
        >
          <li
            :class="[isExactActive && 'router-link-active is-active']"
            role="link"
          >
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Παραγγελίες
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="{ name: 'stats.products' }"
          custom
        >
          <li
            :class="[isExactActive && 'router-link-active is-active']"
            role="link"
          >
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Προϊόντα
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          v-if="isSuperAdmin"
          :to="{ name: 'stats.brands' }"
          custom
        >
          <li
            :class="[isExactActive && 'router-link-active is-active']"
            role="link"
          >
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Μάρκες
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isSuperAdmin: 'getIsSuper',
    }),
  },
};
</script>
