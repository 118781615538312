var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs"},[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'stats.orders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Παραγγελίες ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'stats.products' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Προϊόντα ")])])]}}])}),(_vm.isSuperAdmin)?_c('router-link',{attrs:{"to":{ name: 'stats.brands' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Μάρκες ")])])]}}],null,false,2792013962)}):_vm._e()],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }